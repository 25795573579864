import { useState } from 'react';
import { styled } from '@mui/system';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Paper,
  Rating
} from '@mui/material';

import Lightbox from 'react-18-image-lightbox';
import { ContentCopy } from '@mui/icons-material';

const examplePosterImage = new URL('../../img/poster-example.jpg', import.meta.url);

const PosterImg = styled('img')({
  width: '100%'
});

export default function PaperSlide(props) {

  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [isAuthorDialogOpen, setAuthorDialogOpen] = useState(false);
  const [isVotingDialogOpen, setVotingDialogOpen] = useState(false);
  const [isVotingSucessDialogOpen, setVotingSucessDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [rating, setRating] = useState(null);

  const posterUrl = props.posterData.ficheiro ? 'https://posters-cluster-assets.hosting.mariaadelaide.com/' + props.posterData.ficheiro + '.jpg' : props.posterData.url;
  const voteUrl = process.env.API_BASE_PATH + `/posters/${props.posterData.id}/vote`;
  const messageAuthorUrl = process.env.API_BASE_PATH + `/posters/${props.posterData.id}/message-author`;

  async function postVote() {
    try {
      const response = await fetch(voteUrl, {
          method: 'POST',
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            rating
          })
      });

      if (!response.ok) {
        throw new Error(`Erro no pedido ao servidor: ${response.status} ${response.statusText}`);
      }

      setVotingSucessDialogOpen(true);
    } catch (error) {
      console.error("Error:", error);
      setError(error.toString());
    }
  }

  async function messageAuthor() {
    try {
      let mobile = props.posterData.fields['Telemóvel Autor'];

      if(!mobile.startsWith('+'))
        mobile = '+351' + mobile;

      const response = await fetch(messageAuthorUrl, {
          method: 'POST',
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            title: props.posterData.fields['Título'],
            mobile,
            screen: localStorage.getItem('mupiName') || 'Mupi'
          })
      });

      if (!response.ok) {
        throw new Error(`Erro no pedido ao servidor: ${response.status} ${response.statusText}`);
      }

      setAuthorDialogOpen(true);
    } catch (error) {
      console.error("Error:", error);
      setError(error.toString());
    }
  }

  return <>
    { props.posterData.ficheiro || props.posterData.url ?
      <Paper elevation={3} sx={{ margin: '10%', marginBottom: '25%' }}>
        <PosterImg
          data-src={posterUrl}
          className="lazyload"
          alt=""
          onClick={() => setLightboxOpen(true)}
        />
      </Paper>
      :
      <Box sx={{
        position: 'absolute',
        width: '100vw',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        textAlign: 'center'
      }}>
        Imagem do poster não disponível.
      </Box>
    }

    { isLightboxOpen && (
      <Lightbox
        mainSrc={posterUrl}
        onCloseRequest={() => setLightboxOpen(false)}
      />
    )}
  </>;
}
