import { createTheme } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#043f1f'
    },
    secondary: {
      main: yellow[700]
    }
  },
  typography: {
    body1: {
      letterSpacing: 0
    },
    body2: {
      letterSpacing: 0
    }
  }
});

export default theme;
