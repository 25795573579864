import {
  useState,
  useEffect,
  createContext
} from 'react';

import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';

import { ThemeProvider } from '@mui/material/styles';

import ListIcon from '@mui/icons-material/List';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

import theme from './theme';

import ListTab from './ListTab';
import GalleryTab from './GalleryTab';

import AllData from '../data/all.json';

export const AppContext = createContext();

export function App() {

  const [ context, setContext ] = useState({
    tab: 0,
    posterIndex: 0
  });

  const [postersData, setPostersData] = useState(null);
  const [mupiName, setMupiName] = useState(localStorage.getItem('mupiName') || 'Mupi');
  const [mupiNameDialogOpen, setMupiNameDialogOpen] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if(searchParams.has('set'))
      setPostersData(AllData.filter(item => item.set == searchParams.get('set')) || []);
    else
      setPostersData(AllData);

    console.log(AllData);
  },[]);

  useEffect(() => {
    localStorage.setItem('mupiName', mupiName);
  }, [mupiName]);

  return (
    <AppContext.Provider value={{
      ...context,
      setPosterIndex: (index) => {
        setContext({
          ...context,
          posterIndex: index
        });
      },
      setTab: (tab) => {
        setContext({
          ...context,
          tab
        });
      },
      setContext
    }}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }}>

          <Box>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>Cluster Habitat Sustentável</Typography>
                <Button color="inherit" onClick={() => setMupiNameDialogOpen(true)}>{mupiName}</Button>
              </Toolbar>
            </AppBar>
          </Box>

          <Box sx={{
            flexGrow: 1,
            minHeight: 0,
            overflow: 'hidden'
          }}>
            { context.tab == 0 && <ListTab postersData={postersData} /> }
            { context.tab == 1 && <GalleryTab postersData={postersData} /> }
          </Box>

          <Box>
            <Paper elevation={4}>
              <BottomNavigation
                showLabels
                value={context.tab}
                onChange={(event, newValue) => {
                  setContext({
                    ...context,
                    tab: newValue
                  });
                }}
              >
                <BottomNavigationAction label="Ver em Lista" icon={<ListIcon />} />
                <BottomNavigationAction label="Ver em Galeria" icon={<ViewCarouselIcon />}/>
              </BottomNavigation>
            </Paper>
          </Box>

        </Box>

        {mupiNameDialogOpen && (
          <Dialog
            open={mupiNameDialogOpen}
            onClose={() => setMupiNameDialogOpen(false)}
          >
            <DialogTitle>Nome do Mupi</DialogTitle>
            <DialogContent>
              <TextField
                sx={{ marginTop: 1 }}
                label="Nome"
                onChange={(event) => {
                  if(event.target.value) {
                    setMupiName(event.target.value);
                  }
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setMupiNameDialogOpen(false);

              }}>
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        )}

      </ThemeProvider>
    </AppContext.Provider>
  );
}
