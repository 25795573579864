[
  {
    "set": "M1",
    "numero": 1,
    "titulo": "AQUA+ O referencial de eficiência hídrica para os edifícios",
    "autor": "P. M. Dias (ADENE – Agência para a Energia)",
    "ficheiro": "1_ADENE_AQUA+_Poster_MUPI"
  },
  {
    "set": "M1",
    "numero": 2,
    "titulo": "Portal casA+ – One-Stop-Shop da eficiência",
    "autor": "A. Batalha (ADENE – Agência para a Energia)",
    "ficheiro": "2_ADENE_casA+_Poster_MUPI"
  },
  {
    "set": "M1",
    "numero": 3,
    "titulo": "iBRoad2EPC – Passaportes para a renovação dos edifícios e integração no SCE",
    "autor": "J. Cleto (ADENE – Agência para a Energia)",
    "ficheiro": "3_ADENE_JCleto_iBRoad2EPC_Poster_MUPI"
  },
  {
    "set": "M1",
    "numero": 4,
    "titulo": "R.U.-I.S. – Reabilitação Urbana Inteligente e Sustentável",
    "autor": "C. Cardoso (AICCOPN)",
    "ficheiro": "4_AICCOPN_Poster_MUPI"
  },
  {
    "set": "M1",
    "numero": 5,
    "titulo": "DECARBWOOD: Roteiro para a descarbonização do setor da madeira",
    "autor": "A. Ribeiro (AIMMP)",
    "ficheiro": "5-_AIMMP_Poster_MUPI"
  },
  {
    "set": "M1",
    "numero": 6,
    "titulo": "Skintech como solução ecológica para a nova construção e renovação",
    "autor": "A. Lamas (Aleluia Cerâmicas)",
    "ficheiro": "6_ALELUIA_Poster_MUPI"
  },
  {
    "set": "M1",
    "numero": 7,
    "titulo": "Cortiça – uma solução da natureza",
    "autor": "C. M. Silva (Amorim Cork Insulation)",
    "ficheiro": "7_Amorim Cork Insulation_Poster_MUPI"
  },
  {
    "set": "M1",
    "numero": 8,
    "titulo": "Digitalizar a fileira dos materiais de construção",
    "autor": "J. Matos (APCMC)",
    "ficheiro": "8_APCMC_Poster_MUPI"
  },
  {
    "set": "M1",
    "numero": 9,
    "titulo": "THERMIC-Wall",
    "autor": "R. Tavares (Areadobras)",
    "ficheiro": "9_Areadobras_Poster_MUPI"
  },
  {
    "set": "M1",
    "numero": 10,
    "titulo": "Janelas ainda mais eficientes e sustentáveis",
    "autor": "J. Gomes (CAIXIAVE)",
    "ficheiro": "10_CAIXIAVE_Poster_MUPI"
  },
  {
    "set": "M2",
    "numero": 1,
    "titulo": "Valorização sustentável de resíduos e subprodutos promovendo a circularidade",
    "autor": "A. Carvalho (CeNTI)",
    "ficheiro": "1_CENTI_AnabelaCarvalho_Poster_MUPI"
  },
  {
    "set": "M2",
    "numero": 2,
    "titulo": "Soluções inteligentes e disruptivas promovendo o conforto e segurança em edifícios",
    "autor": "D. Campanhã (CeNTI)",
    "ficheiro": "2_CENTI_Daniela Campanhã_Poster_MUPI"
  },
  {
    "set": "M2",
    "numero": 3,
    "titulo": "Soluções disruptivas para gestão inteligente de recursos e transição energética eficiente",
    "autor": "P. Fernandes (CeNTI)",
    "ficheiro": "3_CENTI_Patrícia Fernandes_Poster_MUPI"
  },
  {
    "set": "M2",
    "numero": 4,
    "titulo": "Declarações ambientais de produtos: o quê, porquê, quem e como?",
    "autor": "M. Silva (CERTIF)",
    "ficheiro": "4_CERTIF_Poster1_MUPI"
  },
  {
    "set": "M2",
    "numero": 5,
    "titulo": "CERTIF – Associação para a Certificação",
    "autor": "M. Silva (CERTIF)",
    "ficheiro": "5_CERTIF_Poster2_MUPI"
  },
  {
    "set": "M2",
    "numero": 6,
    "titulo": "Soluções Eficientes de Iluminação Natural para Edifícios – Tubos Solares",
    "autor": "C. Brandão, J. Brandão (Chatron, Lda)",
    "ficheiro": "6_Chatron1_Poster_MUPI"
  },
  {
    "set": "M2",
    "numero": 7,
    "titulo": "Soluções Eficientes de Iluminação Natural para Edifícios – Cúpulas Planas Transitáveis",
    "autor": "C. Brandão, J. Brandão (Chatron, Lda)",
    "ficheiro": "7_Chatron2_Poster_MUPI"
  },
  {
    "set": "M2",
    "numero": 8,
    "titulo": "Soluções Eficientes de Iluminação Natural para Edifícios - Linha de Arquitetura",
    "autor": "C. Brandão, J. Brandão (Chatron, Lda)",
    "ficheiro": "8_Chatron3_Poster_MUPI"
  },
  {
    "set": "M2",
    "numero": 9,
    "titulo": "Soluções Eficientes de Iluminação Natural para Edifícios – Cúpulas Solares Especiais",
    "autor": "C. Brandão, J. Brandão (Chatron, Lda)",
    "ficheiro": "9_Chatron4_Poster_MUPI"
  },
  {
    "set": "M2",
    "numero": 10,
    "titulo": "Smart Roofs System",
    "autor": "A. Silva (CITEVE)",
    "ficheiro": "10_CITEVE_Poster_MUPI"
  },
  {
    "set": "M3",
    "numero": 1,
    "titulo": "Financiamento e apoio à Transição Digital e Verde da Fileira do Habitat Sustentável",
    "autor": "A. Soares, R. Luz (CIVITTA)",
    "ficheiro": "1_CIVITTA_Poster_MUPI"
  },
  {
    "set": "M3",
    "numero": 2,
    "titulo": "Solução Integrada para Soluções Energéticas Cleanwatts",
    "autor": "R. Queiroga (Cleanwatts)",
    "ficheiro": "2_Cleanwatts_Poster_MUPI"
  },
  {
    "set": "M3",
    "numero": 3,
    "titulo": "ActiVas - Aumentar a segurança do habitat através da integração de soluções tecnológicas nos materiais cerâmicos",
    "autor": "D. Dias, R. Malgueiro, A. Carvalho, I. Sá (CeNTI), I. Rondão*, V. Francisco (CTCV), A. Lamas (Aleluia Cerâmicas S.A.)",
    "ficheiro": "3_CTCV_Poster_ACTIVAS"
  },
  {
    "set": "M3",
    "numero": 4,
    "titulo": "Passaporte Digital de Produto para Produtos de construção",
    "autor": "I. Rondão, L. Hennetier, M. Almeida, V. Francisco (CTCV)",
    "ficheiro": "4_CTCV_Poster_PDP"
  },
  {
    "set": "M3",
    "numero": 5,
    "titulo": "Superfícies cerâmicas biocidas inovadoras para um ambiente seguro e limpo",
    "autor": "L. Hennetier, I. Rondão, C. Monteiro (CTCV)",
    "ficheiro": "5_CTCV_Poster__Biocide_LH"
  },
  {
    "set": "M3",
    "numero": 6,
    "titulo": "Additive manufacturing of natural stone-based materials: assessment of binder-jetting capabilities",
    "autor": "H. Jorge (CTCV)",
    "ficheiro": "6_CTCV_Poster_CTCV - FA Pedra"
  },
  {
    "set": "M3",
    "numero": 7,
    "titulo": "A contribuição das Declarações Ambientais de Produto para o desempenho ambiental",
    "autor": "M. Almeida, A. Amado, M. Lopes (CTCV)",
    "ficheiro": "7_CTCV_Cluster_Poster_DAP"
  },
  {
    "set": "M3",
    "numero": 8,
    "titulo": "ESG - Normas Europeias de Relato de Sustentabilidade",
    "autor": "M. Almeida, M. Ferreira, A. Figueiredo (CTCV)",
    "ficheiro": "8_Cluster_Template_Poster_ESG"
  },
  {
    "set": "M3",
    "numero": 9,
    "titulo": "Matérias-Primas Críticas: contexto da indústria cerâmica em Portugal",
    "autor": "A. Amado, M. Almeida, M. Lopes (CTCV), C. Rocha (LNEG)",
    "ficheiro": "9_CTCV_Poster_MPC"
  },
  {
    "set": "M3",
    "numero": 10,
    "titulo": "Regulamento dos Produtos de Construção - CPR acquis – Ambiente e Sustentabilidade",
    "autor": "M. Almeida, M. Lopes, A.Figueiredo, V. Almeida, V. Francisco (CTCV)",
    "ficheiro": "10_CTCV_Poster_CPRacquis_sustentabilidade"
  },
  {
    "set": "M4",
    "numero": 1,
    "titulo": "Produtos Sustentáveis na União Europeia - desde a conceção ecológica",
    "autor": "M. Almeida, A. Figueiredo, A. Amado, P. Frade, M. Lopes (CTCV)",
    "ficheiro": "1_CTCV_Poster_ConcecaoEcologica"
  },
  {
    "set": "M4",
    "numero": 2,
    "titulo": "CeramicLowCO2: Roteiro para a Neutralidade Carbónica da Indústria Cerâmica até 2050",
    "autor": "M. Almeida, C. Monteiro, I. Rondão, P. Frade e V. Francisco (CTCV)",
    "ficheiro": "2_CTCV_Poster_Roteiro_Cerâmica_vf"
  },
  {
    "set": "M4",
    "numero": 3,
    "titulo": "Roteiro para a Descarbonização da Pedra Natural – ROADTO2050",
    "autor": "M. Almeida, C. Monteiro, I. Rondão, P. Frade, V. Francisco (CTCV)",
    "ficheiro": "3_CTCV_Poster_Roteiro_Pedra_v2_CM_MA"
  },
  {
    "set": "M4",
    "numero": 4,
    "titulo": "Ecocerâmica e Cristalaria de Portugal (ECP) - contributo para a transição verde",
    "autor": "I. Rondão, L. Hennetier, M. Almeida, V. Francisco (CTCV)",
    "ficheiro": "4_CTCV_Poster_ECP"
  },
  {
    "set": "M4",
    "numero": 5,
    "titulo": "Roteiro para a Descarbonização na Indústria do Vidro de Embalagem e Cristalaria até 2050 – RODIV2050",
    "autor": "M. Almeida, B. Freitas, V. Francisco, S. Carvalho, C. Monteiro (CTCV)",
    "ficheiro": "5_CTCV_Poster_Roteiro Vidro"
  },
  {
    "set": "M4",
    "numero": 6,
    "titulo": "Requisitos de Sustentabilidade do Pavimento e Revestimento Cerâmico",
    "autor": "M. Almeida, M. Lopes (CTCV)",
    "ficheiro": "6_CTCV_Poster_ISO17889"
  },
  {
    "set": "M4",
    "numero": 7,
    "titulo": "Comércio Europeu de licenças de emissão (CO2) – perspetivas para 2026-2030",
    "autor": "M. Almeida, P. Frade, A. Figueiredo, M. Lopes (CTCV)",
    "ficheiro": "7_CTCV_Cluster_Poster_CELE"
  },
  {
    "set": "M4",
    "numero": 8,
    "titulo": "Avaliação de ciclo de vida: uma ferramenta para a indústria",
    "autor": "L. Nascimento, L. Faria, A. Ribeiro, Z. Genisheva, J. Carvalho (CVR)",
    "ficheiro": "8_CVR_ACV_Poster_MUPI"
  },
  {
    "set": "M4",
    "numero": 9,
    "titulo": "Footwear waste recycling: towards sustainable roads",
    "autor": "A. R. Costa, L. Nascimento, L. Faria, N. Valério, J. Carvalho (CVR)",
    "ficheiro": "9_CVR_BioShoes_Poster_MUPI"
  },
  {
    "set": "M4",
    "numero": 10,
    "titulo": "Tecnologias Verdes, Ecoinovação, Energia e Circularidade",
    "autor": "A. Ribeiro, L. Nascimento, L. Faria, Z. Genisheva, J. Carvalho (CVR)",
    "ficheiro": "10_CVR_DIAs_Poster_MUPI"
  },
  {
    "set": "M5",
    "numero": 1,
    "titulo": "Certificação de Projeto FSC®",
    "autor": "J. M. Castro (FSC Portugal)",
    "ficheiro": "1_FSC Portugal_Poster_MUPI"
  },
  {
    "set": "M5",
    "numero": 2,
    "titulo": "Greenlab - Embracing a Sustainable  World",
    "autor": "I. Santos, M. Pina (Greenlab)",
    "ficheiro": "2_Greenlab_Poster_MUPI"
  },
  {
    "set": "M5",
    "numero": 3,
    "titulo": "Digital Twins: um caminho para a sustentabilidade das Cidades: Case study do projeto RESIST",
    "autor": "R. Sousa (INOVA+)",
    "ficheiro": "3_INOVA+_Poster_MUPI"
  },
  {
    "set": "M5",
    "numero": 4,
    "titulo": "Lufapo Hub - Empreendedorismo criativo para o New European Bauhaus",
    "autor": "A. C. Oliveira (CTCV)",
    "ficheiro": "4_Poster_CTCV_Lufapo HUb"
  },
  {
    "set": "M5",
    "numero": 5,
    "titulo": "Valorização de Placas de Fibrocimento: Solução de Economia Circular para o Problema do Amianto na Construção",
    "autor": "N. Oliveira, A. Curado (IPVC)",
    "ficheiro": "5_IPVC_FiberRec_Poster_MUPI"
  },
  {
    "set": "M5",
    "numero": 6,
    "titulo": "RnProbe2 – Sistema de Avaliação e Mitigação do Gás Radão em Tempo Real",
    "autor": "P. Barros, S. I. Lopes, A. Curado (IPVC)",
    "ficheiro": "6_IPVC_RnProbe2_Poster_MUPI"
  },
  {
    "set": "M5",
    "numero": 7,
    "titulo": "Adaptive – Reabilitação sustentável de coberturas de edifícios",
    "autor": "L. Carvalho, N. Ferreira, J. Martins (IPViseu)",
    "ficheiro": "7_IPViseu_Adaptive_Poster_MUPI"
  },
  {
    "set": "M5",
    "numero": 8,
    "titulo": "SprayCork – Revestimentos de cortiça projetada",
    "autor": "L. Carvalho, N. Ferreira, S. Silva, J. Martins (IPViseu)",
    "ficheiro": "8_IPViseu_SprayCork_Poster_MUPI"
  },
  {
    "set": "M5",
    "numero": 9,
    "titulo": "ViCtor – Desenvolvimento de compactos reciclados",
    "autor": "J. Martins, N. Ferreira, C. Costa, L. Carvalho (IPViseu)",
    "ficheiro": "9_IPViseu_Victor_Poster_MUPI"
  },
  {
    "set": "M5",
    "numero": 10,
    "titulo": "Energia no Ambiente Construído",
    "autor": "L. Aelenei, H. Gonçalves (LNEG)",
    "ficheiro": "10_LNEG_HG_Poster_MUPI"
  },
  {
    "set": "M6",
    "numero": 1,
    "titulo": "Biochar: Um legado intemporal na Construção Sustentável",
    "autor": "J. Pinheiro, S. Guerreiro, S. Rodrigues (Mota-Engil ATIV)",
    "ficheiro": "1_ME ATIV_Biochar_Poster_MUPI"
  },
  {
    "set": "M6",
    "numero": 2,
    "titulo": "Eficiência Hídrica, Energética e Carbónica para um Ambiente Construído mais Sustentável",
    "autor": "J. Pinheiro, S. Guerreiro, S. Rodrigues (Mota-Engil ATIV)",
    "ficheiro": "2_ME ATIV_Geral_Poster_MUPI"
  },
  {
    "set": "M6",
    "numero": 3,
    "titulo": "Venha descobrir o futuro da construção!",
    "autor": "M. Ferreira, A. Caldeira (MODTECH – Sistemas Modulares)",
    "ficheiro": "3_MODTECH_Poster_MUPI"
  },
  {
    "set": "M6",
    "numero": 4,
    "titulo": "LIGHT STEEL FRAMING - Construção Sustentável em Aço Leve",
    "autor": "G. Martins (PERFISA)",
    "ficheiro": "4_PERFISA_Poster_MUPI"
  },
  {
    "set": "M6",
    "numero": 5,
    "titulo": "O contributo da cerâmica para a transição Verde e Digital da Construção",
    "autor": "N. Vieira (Revigrés)",
    "ficheiro": "5_REVIGRES_Poster_MUPI"
  },
  {
    "set": "M6",
    "numero": 6,
    "titulo": "Investigação e Desenvolvimento no Apoio à Construção em Madeira",
    "autor": "A. Dias (SerQ)",
    "ficheiro": "6_SERQ_Poster_MUPI"
  },
  {
    "set": "M6",
    "numero": 7,
    "titulo": "Sika® Sarnafil® AT TECNOLOGIA AVANÇADA",
    "autor": "D. Santos (SIKA)",
    "ficheiro": "7_SIKA_DS_Poster_MUPI"
  },
  {
    "set": "M6",
    "numero": 8,
    "titulo": "Projeto e Coordenação: Estratégia para a Eficiência Global",
    "autor": "D. Leite (SOPSEC)",
    "ficheiro": "8_SOPSEC_Poster_MUPI"
  },
  {
    "set": "M6",
    "numero": 9,
    "titulo": "Soluções BIM – Building Information Modeling",
    "autor": "C. Antunes (StratBIM)",
    "ficheiro": "9_StratBIM_Poster_MUPI"
  },
  {
    "set": "M6",
    "numero": 10,
    "titulo": "O futuro “envelope” dos edifícios",
    "autor": "A. Teixeira (T&T)",
    "ficheiro": "10_T&T_Poster_MUPI"
  },
  {
    "set": "M7",
    "numero": 1,
    "titulo": "Valorização de resíduos de minas em cerâmicos e cimentos",
    "autor": "O. Naranjo, G. Ascensão, V. Ferreira (U. Aveiro)",
    "ficheiro": "1_UA_Ceramicos_GA_Poster_MUPI"
  },
  {
    "set": "M7",
    "numero": 2,
    "titulo": "Ecodesign para soluções construtivas",
    "autor": "K. Cornely, G. Ascensão, V.M. Ferreira (U. Aveiro)",
    "ficheiro": "2_UA_Eco design para soluções construtivas_Poster_MUPI"
  },
  {
    "set": "M7",
    "numero": 3,
    "titulo": "Design de argamassas eco-eficientes",
    "autor": "G. Ascensão, E. Farinini, V. Ferreira, R. Leardi (U. Aveiro, Università di Genova)",
    "ficheiro": "3_UA_GA_LC3_Poster_MUPI"
  },
  {
    "set": "M7",
    "numero": 4,
    "titulo": "Redução da Pegada Ambiental de Sistemas Sanitários",
    "autor": "L. Souza, V. Brandão, A. C. Dias, I. Meireles (U. Aveiro), M. Pereira (OLI)",
    "ficheiro": "4_UA_LMeireles_Poster_MUPI"
  },
  {
    "set": "M7",
    "numero": 5,
    "titulo": "Soluções Sustentáveis do Ciclo Urbano da Água",
    "autor": "I. Meireles (U. Aveiro)",
    "ficheiro": "5_UA_LMeireles_Poster2_MUPI"
  },
  {
    "set": "M7",
    "numero": 6,
    "titulo": "Dispositivo de Sombreamento Dinâmico com Incorporação de Materiais de Mudança de Fase",
    "autor": "M. Gonçalves, A. Figueiredo, R. Almeida, R. Vicente, A. Samagaio (U. Aveiro)",
    "ficheiro": "6_UA_MargaridaGonçalves_Poster_MUPI"
  },
  {
    "set": "M7",
    "numero": 7,
    "titulo": "Construção Sustentável – Habitação Modular Evolutiva",
    "autor": "A. R. Pinto (U. Lusíada)",
    "ficheiro": "7_ULusiada_Poster_MUPI_1"
  },
  {
    "set": "M7",
    "numero": 8,
    "titulo": "Construção Sustentável - Blocos de encaixe de baixo carbono",
    "autor": "A. R. Pinto (U. Lusíada)",
    "ficheiro": "8_ULusiada_Poster_MUPI_2"
  },
  {
    "set": "M7",
    "numero": 9,
    "titulo": "Construção Sustentável - Biomateriais e Resíduos em Paredes Exteriores de Edifícios",
    "autor": "A. R. Pinto, M. C. Urbano (U. Lusíada)",
    "ficheiro": "9_ULusiada_Poster_MUPI_3"
  },
  {
    "set": "M7",
    "numero": 10,
    "titulo": "Construção Sustentável - Desconstrução e Desmantelamento Seletivo",
    "autor": "A. R. Pinto, C. O. Augusto (U. Lusíada)",
    "ficheiro": "10_ULusiada_Poster_MUPI_4"
  },
  {
    "set": "M8",
    "numero": 1,
    "titulo": "Weberfloor pump truck",
    "autor": "V. Fernandes (Saint-Gobain Portugal)",
    "ficheiro": "1_Saint-Gobain_Poster_MUPI"
  },
  {
    "set": "M8",
    "numero": 2,
    "titulo": "TELHAS ADVANCE DA UMBELINO MONTEIRO",
    "autor": "M. Franco (Umbelino Monteiro)",
    "ficheiro": "2_Umbelino Monteiro_Poster_MUPI"
  },
  {
    "set": "M8",
    "numero": 3,
    "titulo": "Pavimentos Radiantes com Incorporação de Materiais de Mudança de Fase: Avaliação de Desempenho",
    "autor": "F. Rebelo, A. Figueiredo, R. Almeida, R. Vicente, V. Ferreira (U. Aveiro)",
    "ficheiro": "3_UA_FRebelo_Poster_MUPI"
  },
  {
    "set": "M8",
    "numero": 4,
    "titulo": "Conforto térmico e eficiência energética de dois sistemas construtivos com diferentes inércias térmicas",
    "autor": "A. Figueiredo; R.M.S.F. Almeida; R. Vicente; V. Ferreira (U. Aveiro)",
    "ficheiro": "4_UA_AJF1_Poster_MUPI"
  },
  {
    "set": "M8",
    "numero": 5,
    "titulo": "Desenvolvimento de Painéis Biocompósitos produzidos a partir de Fibras de Coco",
    "autor": "E. Vettorazzi; A. Figueiredo; G. Vela; G. Ascensão; R. Vicente; M. Oliveira (UA)",
    "ficheiro": "5_UA_AJF2_Poster_MUPI"
  },
  {
    "set": "M8",
    "numero": 6,
    "titulo": "Saúde e bem-estar, integração social e sustentabilidade: O caminho para o empreendimento do futuro",
    "autor": "B. Melo (CIVILRIA)",
    "ficheiro": "6_CIVILRIA_Poster_MUPI"
  },
  {
    "set": "M8",
    "numero": 7,
    "titulo": "Ecodesign aplicado ao Habitat - DEGREN+",
    "autor": "A.K. Santos, M. Almeida, V. Francisco, V.M. Ferreira (CHS, CTCV)",
    "ficheiro": "7_CHS_CTCV_Degren+_Poster_MUPI"
  },
  {
    "set": "M8",
    "numero": 8,
    "titulo": "Soluções Acústicas",
    "autor": "J. Castro (Artnovion)",
    "ficheiro": "8_Artnovion_Poster_MUPI"
  },
  {
    "set": "M8",
    "numero": 9,
    "titulo": "AM|48 - Sustentabilidade e Desenvolvimento Urbano",
    "autor": "A. Martins (AM|48)",
    "ficheiro": "9_AM48_Poster_MUPI"
  },
  {
    "set": "M8",
    "numero": 10,
    "titulo": "Fio-blu - Plataforma de E-Commerce para Soluções Sustentáveis",
    "autor": "Cristina Maia, Grupo Casais",
    "url": "https://staging.mariaadelaide.com/10%20Cluster_Template_Poster_MUPI_Casais_Fioblu_page-0001.jpg"
  },
  {
    "set": "M8",
    "numero": 11,
    "titulo": "Aplicação de Tecnologias 3D na Conservação e Recuperação de Elementos do Património Edificado",
    "autor": "Inês Bourgeois, Guilherme Ascensão, Victor Ferreira, Hugo Rodrigues",
    "url": "https://staging.mariaadelaide.com/11%20Cluster_Poster_IB_page-0001.jpg"
  }
]
